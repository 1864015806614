import { styled } from "@mui/system";
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

export const Main = styled('div')({
  padding: '0 1.03vw'
})

export const StyledTableCell = styled(TableCell)({
  border: 0,
  fontSize: '0.75vw'
})

export const StyledTableRow = styled(TableRow)({
  borderRadius: '16px',
  '&:nth-of-type(odd)': {
    background: 'rgba(234, 230, 249, 1)',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  }
})