import { createReducer } from '@reduxjs/toolkit';
import { setToken } from './actions';

const initialState = {
  token: '',
};

export default createReducer(initialState, {
  [setToken]: (state, action) => {
    state.token = action.payload;
  },
})