import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  styleRadioLabelTitle,
  styleRadioLabelSubtitle,
} from './styles'
import AccessSystemOwn from './components/Own';
import AccessSystemQRCode from './components/QRCode';

const AccessSystemLabel = ({title, subtitle, icon, type, selectedType}) => (
    <Box sx={{marginLeft: '1vw', width: '100%'}}>
      <Box>{icon}</Box>
      <Box sx={{position: 'relative', top: '4vh'}}>
          <Typography sx={styleRadioLabelTitle}>{title}</Typography>
          { 
            type === 'own' && selectedType === 'own' ? <AccessSystemOwn/> :
            type  === 'qr_code' && selectedType === 'QR_code' ? <AccessSystemQRCode /> : 
            <Typography sx={styleRadioLabelSubtitle}>{subtitle}</Typography>
          }
      </Box>
    </Box>
)
export default AccessSystemLabel;