import React from 'react';

const sizes = {
  width: '1.4vw',
  height: '1.34vw'
}

const ActiveParkingIcon = () => {	return (
<svg style={sizes} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="13.5" cy="21.7104" rx="12.5" ry="3.28947" stroke="#A4A4A4"/>
<path fillRule="evenodd" clipRule="evenodd" d="M13.5001 21.4286C13.5001 21.4286 22.4287 15.7577 22.4287 8.37817C22.4287 4.09585 18.4312 0 13.5001 0C8.56899 0 4.57153 4.09585 4.57153 8.37817C4.57153 15.7193 13.5001 21.4286 13.5001 21.4286ZM13.5001 11.9047C15.1438 11.9047 16.4763 10.5722 16.4763 8.92854C16.4763 7.28484 15.1438 5.95235 13.5001 5.95235C11.8564 5.95235 10.5239 7.28484 10.5239 8.92854C10.5239 10.5722 11.8564 11.9047 13.5001 11.9047Z" fill="#6337BE"/>
</svg>
)
}

export default ActiveParkingIcon;

