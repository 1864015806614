import React from 'react';

const sizes = {
  width: "1.19vw", 
  height: "1.19vw"
}

const CheckboxIcon = () => {	return (
<svg style={sizes} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="11.4736" cy="11.5" r="10.5" stroke="#B1B0B6"/>
</svg>
)
}

export default CheckboxIcon;