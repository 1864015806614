import React, { useState, useEffect } from 'react';
import { Box, IconButton, Button, Fade } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import {
  PreviousButtonIcon,
  NextButtonIcon,
  DeleteButtonIcon
} from './SVGs'

function PhotoSlider({photos, onClose, onRemove}) {

  const [currentImageIdx, setCurrentImageIdx] = useState(0);
  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    if(!photos.length) onClose();
  }, [onClose, photos])
  
  const styleFlips = {
    width: '6vh',
    height: '6vh',
    backgroundColor: '#5D31B8',
    borderRadius: '0.83vw'
  };
  
  return (
      <Box className={'photoSlider'} sx={{
        position: 'fixed',
        background: 'rgba(0, 0, 0, 0.8)',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 1,
      }}>
         <IconButton 
          size="large"
          sx={{
            color: 'white',
            position: 'absolute',
            top: '5vh',
            right: '5vw',
            zIndex: 2
          }}
          onClick={onClose}>
          <CloseIcon/>
        </IconButton>

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center',
          alignItems: 'center', 
          height: '100%',
          width: '100%',
          flexDirection: 'column'
        }}>
          <Box sx={{ 
            display: 'flex',
            width: '60%',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10vh',
           }}>
            <IconButton 
              disableRipple 
              sx={styleFlips}
              onClick={() => setCurrentImageIdx(currentImageIdx-1 < 0 ? photos.length-1 : currentImageIdx-1)}
            >
              <PreviousButtonIcon />
            </IconButton>

            <Box
              onMouseEnter={() => setHovering(true)}
              onMouseLeave={() => setHovering(false)}
            >
              {hovering && (
                <Fade in={hovering}><IconButton
                  disableRipple
                  sx={{
                    ...styleFlips,
                    width: '8vh',
                    height: '8vh',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -115%)',
                  }}
                  onClick={() => {photos.filter(photo => photo.index !== currentImageIdx); onRemove(photos[currentImageIdx].url)}}
                >
                  <DeleteButtonIcon />
                </IconButton></Fade>
                )}
              <img alt='' src={photos[currentImageIdx].url} style={{borderRadius: '0.83vw', maxHeight: '30.13vw', maxWidth: '47.82vw'}} />
            </Box>

            <IconButton 
              disableRipple 
              sx={styleFlips}
              onClick={() => setCurrentImageIdx(currentImageIdx+1 === photos.length ? 0 : currentImageIdx+1 )}
            >
              <NextButtonIcon />
            </IconButton>
          </Box>

          <Box sx={{ 
            position: 'absolute', 
            bottom: '10vh', 
            display: 'flex',  
            '& ::-webkit-scrollbar': {
              marginTop: '100px',
              width: '10px'
            },
          }}>
            {photos?.map((photo, index) => (
              <Box key={index}>
                <Button 
                  disableRipple 
                  sx={{ 
                  marginLeft: '1vw', 
                  padding: 0,
                  }}
                  onClick={() => setCurrentImageIdx(index)}
                >
                  <img 
                    alt='' 
                    style={{
                      border: index === currentImageIdx ? '2px solid #5D31B8' : 'none',
                      borderRadius: '0.83vw' ,
                      maxWidth: '25.93vw',
                      maxHeight: '5.18vw',
                    }} 
                    src={photo.url}
                  />
                </Button>
            </Box>
            ))}
          </Box>

        </Box>
      </Box>
  );
}

PhotoSlider.propTypes = {
  photos: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default PhotoSlider;