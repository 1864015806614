import React from 'react';

const sizes = {
  width: '5.7vw',
  height: '4.25vw'
}

const ParkifyLogoSB = () => {	return (
<svg style={sizes} viewBox="0 0 111 84" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M66.4796 47.1433C72.5263 40.9533 78.8213 32.0891 78.8213 22.0123C78.8213 10.7612 68.3873 0 55.5163 0C42.6453 0 32.2113 10.7612 32.2113 22.0123C32.2113 41.3002 55.5163 56.3003 55.5163 56.3003C55.5163 56.3003 58.7223 54.2507 62.756 50.6826C57.6746 47.2892 53.6394 43.7995 50.5307 40.559C46.475 36.3315 43.8988 32.4238 42.6989 29.6293C41.6419 27.6205 41.0437 25.3326 41.0437 22.905C41.0437 14.9184 47.5182 8.44397 55.5047 8.44397C63.4913 8.44397 69.9658 14.9184 69.9658 22.905C69.9658 30.8916 63.4913 37.366 55.5047 37.366C55.1191 37.366 54.737 37.3509 54.359 37.3213C57.376 40.4311 61.3692 43.8335 66.4796 47.1433Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M50.3824 17.2528L49.3863 20.086C48.7644 20.3914 48.3363 21.0311 48.3363 21.7708V27.09C48.3363 27.6151 48.762 28.0407 49.2871 28.0407H50.0837C50.6088 28.0407 51.0344 27.6151 51.0344 27.09V25.7537H60.0796V27.09C60.0796 27.6151 60.5053 28.0407 61.0304 28.0407H61.827C62.3521 28.0407 62.7778 27.6151 62.7778 27.09V21.7708C62.7778 21.0436 62.364 20.413 61.759 20.1017L60.7574 17.2528C60.3668 16.1416 59.3172 15.398 58.1393 15.398H53.0005C51.8226 15.398 50.773 16.1416 50.3824 17.2528ZM58.1393 17.2482H53.0005C52.6079 17.2482 52.258 17.496 52.1278 17.8664L51.4147 19.8949H59.7251L59.012 17.8664C58.8818 17.496 58.5319 17.2482 58.1393 17.2482ZM60.979 22.6187C60.979 23.1154 60.5763 23.5181 60.0796 23.5181C59.5829 23.5181 59.1802 23.1154 59.1802 22.6187C59.1802 22.122 59.5829 21.7193 60.0796 21.7193C60.5763 21.7193 60.979 22.122 60.979 22.6187ZM51.9338 22.6187C51.9338 23.1155 51.5312 23.5181 51.0345 23.5181C50.5377 23.5181 50.1351 23.1155 50.1351 22.6187C50.1351 22.122 50.5377 21.7194 51.0345 21.7194C51.5312 21.7194 51.9338 22.122 51.9338 22.6187Z" fill="#80CC5E"/>
<path d="M4.37374 84H0.45166V68.4833H7.36433C8.91682 68.4833 10.1588 68.6712 11.0903 69.0471C12.0218 69.4229 12.6918 70.0358 13.1004 70.8855C13.5089 71.7353 13.7132 72.8874 13.7132 74.3419C13.7132 75.7146 13.5089 76.834 13.1004 77.7002C12.6918 78.5499 12.0218 79.1791 11.0903 79.5877C10.1588 79.9962 8.91682 80.2005 7.36433 80.2005H4.37374V84ZM6.72699 71.6945H4.37374V76.9893H6.72699C7.88727 76.9893 8.66352 76.7932 9.05572 76.401C9.44793 76.0088 9.64404 75.3224 9.64404 74.3419C9.64404 73.4104 9.44793 72.7404 9.05572 72.3318C8.66352 71.9069 7.88727 71.6945 6.72699 71.6945Z" fill="white"/>
<path d="M32.5876 83.9265V84H28.4204C28.306 83.6078 28.1671 83.1584 28.0037 82.6518C27.8566 82.1452 27.6932 81.6059 27.5134 81.0339H22.2186C21.8918 82.1779 21.5895 83.1666 21.3116 84H17.1444V83.951L22.1696 68.4833H27.5625L32.5876 83.9265ZM24.817 71.2778C24.6536 72.1112 24.4166 73.1244 24.1061 74.3174C23.7956 75.5103 23.4688 76.7278 23.1256 77.9698H26.631C26.2714 76.7278 25.9364 75.5103 25.6259 74.3174C25.3154 73.1244 25.0785 72.1112 24.9151 71.2778H24.817Z" fill="white"/>
<path d="M40.8091 84H36.887V68.4833H44.2899C46.2346 68.4833 47.6482 68.9245 48.5307 69.807C49.4295 70.6731 49.8789 71.956 49.8789 73.6555C49.8789 74.9302 49.5602 76.0251 48.9229 76.9403C48.2855 77.8391 47.4439 78.4029 46.398 78.6317L46.3735 78.7052C46.7821 78.9667 47.2315 79.3997 47.7217 80.0044C48.212 80.609 48.6941 81.2709 49.168 81.9899C49.6583 82.6926 50.0913 83.3545 50.4672 83.9755V84H45.9078L43.2604 80.0779C43.0316 79.7347 42.8436 79.4896 42.6966 79.3425C42.5658 79.1955 42.4024 79.1056 42.2063 79.0729C42.0265 79.0402 41.7324 79.0239 41.3238 79.0239H40.8091V84ZM43.6526 71.67H40.8091V75.8372H43.6526C44.4697 75.8372 45.0335 75.6738 45.344 75.3469C45.6708 75.0201 45.8342 74.489 45.8342 73.7536C45.8342 73.0182 45.6708 72.4871 45.344 72.1602C45.0335 71.8334 44.4697 71.67 43.6526 71.67Z" fill="white"/>
<path d="M59.1823 84H55.2602V68.4833H59.1823V74.9792C59.215 74.9792 59.2395 74.9792 59.2559 74.9792C59.4193 74.9792 59.6072 74.8485 59.8197 74.587C60.0485 74.3255 60.2527 74.0641 60.4325 73.8026L63.7172 68.4833H67.9825V68.5568L65.188 73.0672C64.9429 73.4758 64.6651 73.917 64.3546 74.3909C64.0441 74.8485 63.7336 75.257 63.4231 75.6166C63.1289 75.9597 62.8674 76.1722 62.6387 76.2539V76.3274C62.8674 76.4255 63.1289 76.6379 63.4231 76.9648C63.7336 77.2753 64.0441 77.643 64.3546 78.0679C64.6814 78.4764 64.9919 78.885 65.2861 79.2935L68.5463 83.9265V84H64.0359L60.9718 79.2935V79.318C60.8737 79.1546 60.7185 78.9422 60.506 78.6807C60.3099 78.4029 60.0975 78.1496 59.8687 77.9208C59.6562 77.692 59.4683 77.5776 59.3049 77.5776C59.2722 77.5776 59.2477 77.5776 59.2313 77.5776C59.215 77.5776 59.1987 77.5776 59.1823 77.5776V84Z" fill="white"/>
<path d="M76.622 84H72.6999V68.4833H76.622V84Z" fill="white"/>
<path d="M86.0662 84H82.1441V68.4833H93.6652V71.67H86.0662V75.2243H93.175V78.411H86.0662V84Z" fill="white"/>
<path d="M101.63 84V79.1955L96.6049 68.5568V68.4833H100.895C101.401 69.807 101.802 70.8692 102.096 71.67C102.406 72.4544 102.643 73.0754 102.807 73.533C102.986 73.9742 103.125 74.3337 103.223 74.6115C103.321 74.8893 103.428 75.1835 103.542 75.494H103.64C103.754 75.1835 103.861 74.8893 103.959 74.6115C104.057 74.3337 104.188 73.9742 104.351 73.533C104.531 73.0754 104.768 72.4544 105.062 71.67C105.372 70.8692 105.781 69.807 106.287 68.4833H110.577V68.5568L105.552 79.1955V84H101.63Z" fill="white"/>
</svg>
)
}

export default ParkifyLogoSB;

