import axios from 'axios';

export default class ApiManager {
  constructor() {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      timeout: 3000,
    })
  }

  async get({url, config}) {
    const response = await this.axios.get(url, config);

    console.log(response)
    return response;
  };

  async post({url, data={}, config={}}) {
    const response = await this.axios.post(url, data, config);

    console.log(response)
    return response;
  };

  async patch({url, data={}, config}) {
    const response = await this.axios.patch(url, data, config);

    console.log(response)
    return response;
  };

  async delete({url, config}) {
    const response = await this.axios.delete(url, config);

    console.log(response)
    return response;
  };
}