import React from 'react';
import './App.css';
import routes from './routes';
import { useRoutes } from "react-router-dom";
// const history = createBrowserHistory();

function App() {
  let element = useRoutes(routes);

  return (
    <div>
      {/* <h1>Route Objects Example</h1> */}
      {element}
    </div>
    )
}

export default App;