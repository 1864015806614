import React from 'react';
import {
  Chart as ChartJS,
  registerables
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  ...registerables
);

const getRandomArbitrary = ({min, max}) => {
  return Math.random() * (max - min) + min;
}

const labels = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль'];

const data = {
  labels,
  datasets: [
    {
      type: 'line',
      label: 'Dataset 1',
      borderColor: 'rgba(93, 49, 184, 1)',
      borderWidth: 2,
      fill: false,
      data: labels.map(() => getRandomArbitrary({ min: 300, max: 1000 })),
    },
    {
      type: 'bar',
      label: 'Dataset 2',
      backgroundColor: 'rgba(128, 204, 94, 1)',
      barThickness: 20,
      data: labels.map(() => getRandomArbitrary({ min: 300, max: 1000 })),
      borderWidth: 0
    }
  ],
};

const ChartAnalytics = () => {
  return <Chart type='bar' data={data} height={'100vh'} />;
}

export default ChartAnalytics