import React, {useState} from 'react';
import Page from './../../components/Page';
import { Grid, Typography, Box } from "@mui/material";
import DealsTable from "./DealsTable";
import DateRange from "./DateRange";
import Sidebar from '../../components/Sidebar'
import Helmet from '../../components/Helmet'

const Deals = () => {
  const [createDate, setCreateDate] = useState([]);
  const [closeDate, setCloseDate] = useState([]);

  const styleMain = {minHeight: "100vh", background: '#F0EFF4'}

  return (<Page title="Сделки">
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      sx={styleMain}
    >
      <Grid item xs={1.7}>
        <Sidebar />
      </Grid>
      
      <Grid item xs={1}></Grid>

      <Grid item xs={8}>
        <Helmet />

        <Box>
          <Typography sx={{
            fontSize: '1.55vw',
            color: '#5D31B8',
          }}>Сделки</Typography>
        </Box>

        <Box sx={{ marginTop: '3vh'}}>
          <Box sx={{display: 'flex'}}>
            <Box><DateRange title="По дате создания" onChange={setCreateDate} /></Box>
            <Box sx={{marginLeft: '5vw'}}><DateRange title="По дате закрытия"  onChange={setCloseDate}/></Box>
          </Box>
        </Box>

        <Box sx={{marginTop: '5vh'}}>
          <DealsTable />
        </Box>
      </Grid>

      <Grid item xs={1.3}></Grid>

    </Grid>
  </Page>)
}

export default Deals;