import { styled } from '@mui/styles';
import React from 'react';
import { Helmet } from 'react-helmet';
import { HEIGHT_TOPBAR } from './../../constants/appConst';

const StyledPage = styled('div')({
  width: '100%',
  minHeight: `calc(100vh - ${HEIGHT_TOPBAR}px)`
})

const Page = props => {
  const { title, children, ...rest } = props;

  return (
    <StyledPage {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </StyledPage>
  );
};


export default Page;
