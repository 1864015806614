import React from 'react';

const sizes = {
  width: '1.81vw',
  height: '1.03vw'
}

const ParkingIconSB = () => {	return (
<svg style={sizes} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M1.21223 8.0933C1.21223 4.87492 4.23451 1.71565 8 1.71565C11.7655 1.71565 14.7878 4.87492 14.7878 8.0933C14.7878 10.9328 13.072 13.507 11.257 15.4224C10.359 16.37 9.45906 17.1327 8.78312 17.6586C8.46286 17.9077 8.19401 18.1028 8.00102 18.2384C7.8079 18.102 7.5387 17.9056 7.21796 17.6549C6.54183 17.1265 5.64165 16.3606 4.74344 15.4108C2.92748 13.4905 1.21223 10.9164 1.21223 8.0933ZM7.67158 19.4811C7.67178 19.4813 7.67195 19.4814 8 18.9717L7.67195 19.4814L7.9989 19.6918L8.32648 19.4824L8 18.9717C8.32648 19.4824 8.32666 19.4823 8.32686 19.4821L8.32741 19.4818L8.32901 19.4807L8.33422 19.4774L8.35251 19.4655C8.36814 19.4553 8.39059 19.4405 8.41938 19.4213C8.47697 19.3829 8.56001 19.3267 8.66487 19.2534C8.87453 19.1069 9.17178 18.8921 9.52747 18.6154C10.2379 18.0627 11.1864 17.2592 12.1369 16.2562C14.0188 14.2702 16 11.4052 16 8.0933C16 4.17249 12.4016 0.503418 8 0.503418C3.59843 0.503418 0 4.17249 0 8.0933C0 11.3895 1.98169 14.2547 3.86267 16.2437C4.81294 17.2486 5.76123 18.0549 6.47146 18.61C6.82705 18.888 7.12422 19.1039 7.33379 19.2512C7.43861 19.3248 7.52161 19.3814 7.57916 19.42C7.60794 19.4393 7.63036 19.4542 7.64598 19.4644L7.66424 19.4764L7.66944 19.4797L7.67103 19.4808L7.67158 19.4811Z" fill="white"/>
<path d="M8.53158 9.4635H6.52268V8.30638H8.53158C8.86014 8.30638 9.12621 8.25281 9.32978 8.14567C9.53692 8.03495 9.6887 7.88495 9.78513 7.69567C9.88156 7.50282 9.92977 7.28318 9.92977 7.03675C9.92977 6.79747 9.88156 6.57426 9.78513 6.36712C9.6887 6.15998 9.53692 5.99212 9.32978 5.86355C9.12621 5.73499 8.86014 5.6707 8.53158 5.6707H7.00481V12.3081H5.53162V4.50822H8.53158C9.13871 4.50822 9.65656 4.61714 10.0851 4.835C10.5173 5.04928 10.8458 5.34749 11.0708 5.72963C11.2994 6.10819 11.4137 6.54033 11.4137 7.02604C11.4137 7.5296 11.2994 7.96352 11.0708 8.32781C10.8458 8.69209 10.5173 8.97244 10.0851 9.16886C9.65656 9.36529 9.13871 9.4635 8.53158 9.4635Z" fill="white"/>
</svg>
)
}

export default ParkingIconSB;

