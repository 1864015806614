import { createReducer } from '@reduxjs/toolkit'
import { setProfileData } from './actions'

const initialState = {
  profileData: {
    nickname: '',
    avatar: '',
    availableBalance: 0
  },
};

export default createReducer(initialState, {
  [setProfileData]: (state, action) => {
    state.profileData = action.payload;
  },
})