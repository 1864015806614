import React, { useEffect } from 'react';
import { COLOR_PURPLE } from '../../constants/appConst'
import { Button, Box, Typography } from '@mui/material';
import { 
  ParkifyLogoSB, 
  // AnalyticsIconSB, 
  ParkingIconSB, 
  DealsIconSB, 
  // SettingsIconSB 
} from '../../layouts/SVGs';
import { useLocation, Link } from "react-router-dom";
import {
  LinkName,
  SidebarTab
} from './styles'
import { useNavigate } from "react-router-dom";
import useAuth from '../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../utils/redux/registerReducer/actions';
import { setProfileData } from '../../utils/redux/apiCacheReducer/actions';
import { fetchProfileData } from '../../thunks'

function Sidebar(props) {
  const menu = [
    // {href: '/analytics', name: 'Аналитика', icon: <AnalyticsIconSB />},
    {href: '/parking', name: 'Парковки', icon: <ParkingIconSB />},
    {href: '/deals', name: 'Сделки', icon: <DealsIconSB />},
    // {href: '/settings', name: 'Настройки', icon: <SettingsIconSB />},
  ];

  const [balance, setBalance] = React.useState(0);
  const location = useLocation();
  const pathname = (location?.pathname || '/').split('/').filter(item => item.length)[0];

  const dispatch = useDispatch();
  const {accessToken} = useAuth();
  let navigate = useNavigate();
  
  const onLogout = async () => {
    if(accessToken) {
      dispatch(logout())
    }
    const route = "/login";
    navigate(route);
  }

  const cachedProfileData = useSelector((state) => state.apiCacheReducer.profileData);

  useEffect(() => {
    let nickname, avatar, availableBalance;

    if (Object.values(cachedProfileData).some(Boolean)) {
      ({ nickname, avatar, availableBalance } = cachedProfileData);
    } else {
      fetchProfileData(accessToken).then(data => {
        if(!data) return;
        ({ nickname, avatar, availableBalance } = data);
        nickname = nickname || data.email;
        dispatch(setProfileData({ nickname, avatar, availableBalance }));
      })
    }

    setBalance(availableBalance);
  }, [accessToken, cachedProfileData, dispatch])

  return (
      <Box sx={{ background: COLOR_PURPLE, height: '100vh', position: 'fixed', width: '15vw'  }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', padding: '15%' }}>
          <Link to={'/adding-parking-spaces'}> 
            <ParkifyLogoSB />
          </Link>
        </Box>
        
        <Box sx={{
          marginLeft: '1.5vw',
          marginBottom: '5vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '12vh'
        }}>
          <Box>
            <Typography sx={{ color: '#fff', fontSize: '0.83vw' }}>Баланс</Typography>
          </Box>

          <Box sx={{ marginBottom: '1vh' }}>
            <Typography sx={{
              color: '#80CC5E',
              fontSize: '1.45vw',
              fontWeight: 'bold'
            }}>
              {balance} ₽
            </Typography>
          </Box>

          <Box>
            <Button disableRipple disable={!!balance} sx={{
              display: 'inline-flex',
              backgroundColor: balance > 0 ? '#80CC5E' : '#7145CD',
              color: '#fff',
              boxShadow: 'none',
              borderRadius: '0.83vw',
              padding: '0.77vw 2.59vw',
              textTransform: 'none',
              fontWeight: 'normal',
              '&:hover': {
                backgroundColor: balance > 0 ? '#80CC5E' : '#7145CD',
              },
            }}>
              <Typography sx={{ fontSize: '0.83vw' }}>
                Вывести
              </Typography>
            </Button>
          </Box>
        </Box>

        <Box>
          {menu.map(({href, name, icon}, index) => (
            <SidebarTab key={`tab_${index}`} active={href.includes(pathname)}>
              <Link to={href} style={{textDecoration: 'none'}}>
              <Box sx={{
                display: 'inline-block',
                position: 'relative',
                top: '0.15vw'
              }}>
                {icon}
              </Box>
              <LinkName>{name}</LinkName> 
              </Link>
            </SidebarTab>
          ))}
        </Box>

        <Box
          sx={{
            position: 'absolute',
            bottom: '5vh',
            left: '50%',
            transform: 'translateX(-50%)'
          }}  
        >
          <Button 
            color="inherit" 
            sx={{
              color: "#fff",
              fontFamily: 'Helvetica Neue, Roboto, Arial, sans-serif;',
              fontSize: '0.72vw',
              fontWeight: '400',
              letterSpacing: '0.25vw'
            }}
            onClick={onLogout}
          >ВЫХОД</Button>
        </Box>
    
      </Box>
  );
}

export default Sidebar;