import React from 'react';

const AddressAdornmentIcon = () => {	return (
<svg width="29" height="36" viewBox="0 0 29 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M2.63485 14.6006C2.63485 8.86253 8.02327 3.22988 14.7367 3.22988C21.4502 3.22988 26.8386 8.86253 26.8386 14.6006C26.8386 19.6631 23.7796 24.2526 20.5436 27.6675C18.9426 29.3571 17.3381 30.7169 16.133 31.6545C15.562 32.0987 15.0827 32.4466 14.7386 32.6882C14.3943 32.4451 13.9143 32.0949 13.3424 31.648C12.137 30.7058 10.5321 29.3404 8.93064 27.6469C5.69297 24.2232 2.63485 19.634 2.63485 14.6006ZM14.1512 34.9039C14.1516 34.9041 14.1519 34.9043 14.7367 33.9956L14.1519 34.9043L14.7348 35.2795L15.3188 34.9061L14.7367 33.9956C15.3188 34.9061 15.3191 34.9059 15.3195 34.9057L15.3205 34.9051L15.3233 34.9032L15.3326 34.8972L15.3652 34.8761C15.3931 34.8579 15.4331 34.8316 15.4845 34.7973C15.5871 34.7288 15.7352 34.6285 15.9221 34.4979C16.2959 34.2367 16.8259 33.8537 17.4601 33.3604C18.7267 32.375 20.4178 30.9425 22.1124 29.1541C25.4677 25.6134 28.9999 20.5053 28.9999 14.6006C28.9999 7.61016 22.5843 1.06859 14.7367 1.06859C6.8892 1.06859 0.473565 7.61016 0.473565 14.6006C0.473565 20.4774 4.00672 25.5857 7.36031 29.1319C9.05454 30.9235 10.7453 32.3612 12.0115 33.3509C12.6455 33.8464 13.1753 34.2313 13.549 34.4939C13.7358 34.6252 13.8838 34.7261 13.9864 34.7949C14.0377 34.8294 14.0777 34.8558 14.1056 34.8741L14.1381 34.8954L14.1474 34.9014L14.1502 34.9033L14.1512 34.9039Z" fill="#5D31B8"/>
<path d="M15.6845 16.6486H12.1029V14.5855H15.6845C16.2703 14.5855 16.7447 14.49 17.1076 14.299C17.4769 14.1016 17.7476 13.8342 17.9195 13.4967C18.0914 13.1529 18.1774 12.7613 18.1774 12.3219C18.1774 11.8953 18.0914 11.4974 17.9195 11.128C17.7476 10.7587 17.4769 10.4595 17.1076 10.2302C16.7447 10.001 16.2703 9.8864 15.6845 9.8864H12.9625V21.7202H10.3359V7.81381H15.6845C16.767 7.81381 17.6903 8.00802 18.4543 8.39643C19.2248 8.77847 19.8106 9.31015 20.2117 9.99146C20.6193 10.6664 20.823 11.4369 20.823 12.3028C20.823 13.2006 20.6193 13.9743 20.2117 14.6237C19.8106 15.2732 19.2248 15.7731 18.4543 16.1233C17.6903 16.4735 16.767 16.6486 15.6845 16.6486Z" fill="#5D31B8"/>
</svg>
)
}

export default AddressAdornmentIcon;