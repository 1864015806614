import React from 'react';

const sizes = {
  width: '1.14vw',
  height: '1.29vw'
}

const DeleteButtonIcon = () => {	return (
<svg style={sizes} viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M8.91894 0.0846349C8.45256 0.223474 8.24298 0.356516 7.9123 0.723533C7.63191 1.03472 7.36415 1.61552 7.36415 1.91259C7.36415 2.26695 7.53743 2.24843 4.19929 2.25156C2.49974 2.25316 0.988398 2.27967 0.840683 2.31052C0.543591 2.37254 0.319585 2.5285 0.12707 2.80724C0.0304424 2.94722 0 3.09098 0 3.4079C0 3.76173 0.0260674 3.86152 0.172672 4.06932C0.512964 4.55172 -0.411529 4.51375 11 4.51375C22.4115 4.51375 21.487 4.55172 21.8273 4.06932C21.9739 3.86152 22 3.76173 22 3.4079C22 3.09098 21.9696 2.94722 21.8729 2.80724C21.6894 2.54156 21.47 2.3816 21.1941 2.31238C21.0611 2.27907 19.548 2.25109 17.8315 2.25022C14.4596 2.24849 14.6359 2.26715 14.6359 1.91259C14.6359 1.84364 14.5776 1.61885 14.5063 1.413C14.3322 0.909939 13.8464 0.384231 13.3621 0.17484C13.0167 0.0255421 12.9303 0.0189466 11.1232 0.00375695C9.6352 -0.00876783 9.17603 0.00802051 8.91894 0.0846349ZM2.07798 14.0905L2.09524 21.3356L2.28535 21.9097C2.66126 23.0449 3.43539 24.0069 4.37227 24.5031C5.35728 25.0249 5.0221 24.9998 11 24.9998C16.994 24.9998 16.639 25.027 17.6547 24.4887C18.608 23.9835 19.4081 22.9394 19.7902 21.702C19.8967 21.357 19.9044 20.9127 19.9213 14.0905L19.9392 6.84549H17.3184H14.6975L14.6961 12.3251C14.6948 17.5329 14.689 17.8183 14.5796 18.0789C14.2519 18.859 13.2752 18.9916 12.8015 18.3203L12.6331 18.0816L12.6166 12.4635L12.6002 6.84549H11H9.39979L9.3834 12.4635L9.36695 18.0816L9.19853 18.3203C8.72482 18.9916 7.74813 18.859 7.42041 18.0789C7.31096 17.8183 7.30517 17.5329 7.30388 12.3251L7.30252 6.84549H4.68162H2.06079L2.07798 14.0905Z" fill="white"/>
</svg>
)
}

export default DeleteButtonIcon