import React from 'react';

const sizes = {
  width: "1.29vw", 
  height: "1.81vw"
}

const ParkingPlaceOperatorIcon = () => {	return (
<svg style={sizes} viewBox="0 0 29 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M2.63492 13.8804C2.63492 8.14233 8.02334 2.50968 14.7368 2.50968C21.4503 2.50968 26.8387 8.14233 26.8387 13.8804C26.8387 18.9429 23.7797 23.5324 20.5437 26.9473C18.9427 28.6369 17.3382 29.9967 16.133 30.9343C15.562 31.3785 15.0827 31.7264 14.7386 31.968C14.3943 31.7249 13.9144 31.3747 13.3425 30.9278C12.137 29.9856 10.5321 28.6202 8.93071 26.9267C5.69303 23.503 2.63492 18.9138 2.63492 13.8804ZM14.1513 34.1837C14.1516 34.1839 14.1519 34.1841 14.7368 33.2754L14.1519 34.1841L14.7348 34.5593L15.3189 34.1859L14.7368 33.2754C15.3189 34.1859 15.3192 34.1857 15.3196 34.1855L15.3206 34.1849L15.3234 34.183L15.3327 34.177L15.3653 34.1559C15.3932 34.1377 15.4332 34.1114 15.4845 34.0771C15.5872 34.0086 15.7353 33.9083 15.9222 33.7777C16.296 33.5165 16.826 33.1335 17.4601 32.6402C18.7267 31.6548 20.4179 30.2223 22.1125 28.4339C25.4677 24.8932 29 19.7851 29 13.8804C29 6.88996 22.5844 0.348389 14.7368 0.348389C6.88927 0.348389 0.473633 6.88996 0.473633 13.8804C0.473633 19.7572 4.00679 24.8655 7.36038 28.4117C9.05461 30.2033 10.7453 31.641 12.0116 32.6306C12.6456 33.1262 13.1754 33.5111 13.549 33.7737C13.7359 33.905 13.8839 34.0059 13.9865 34.0747C14.0378 34.1092 14.0778 34.1356 14.1056 34.1539L14.1382 34.1752L14.1475 34.1812L14.1503 34.1831L14.1513 34.1837Z" fill="white"/>
<path d="M15.6845 15.9284H12.1029V13.8653H15.6845C16.2703 13.8653 16.7447 13.7698 17.1076 13.5788C17.4769 13.3814 17.7476 13.114 17.9195 12.7765C18.0914 12.4327 18.1774 12.0411 18.1774 11.6017C18.1774 11.1751 18.0914 10.7771 17.9195 10.4078C17.7476 10.0385 17.4769 9.73925 17.1076 9.51002C16.7447 9.2808 16.2703 9.16618 15.6845 9.16618H12.9625V21H10.3359V7.0936H15.6845C16.767 7.0936 17.6903 7.2878 18.4543 7.67621C19.2248 8.05826 19.8106 8.58993 20.2117 9.27125C20.6193 9.94619 20.823 10.7166 20.823 11.5826C20.823 12.4804 20.6193 13.2541 20.2117 13.9035C19.8106 14.553 19.2248 15.0528 18.4543 15.4031C17.6903 15.7533 16.767 15.9284 15.6845 15.9284Z" fill="white"/>
</svg>
)
}

export default ParkingPlaceOperatorIcon;