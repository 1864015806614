import ApiManager from '../utils/apiManager'

export default class ProfileService {
  constructor() {
    this.apiManager = new ApiManager();
  }

  async getUserProfile(accessToken) {
    try {
      const response = await this.apiManager.get({
        url: '/api/user/profile',
        config: {
          headers: {
            Authorization: `token ${accessToken}`
          },
        },
      });
    
      return response.data;
    } catch (error) {
      console.log(error)
    }
  }

  signIn() {}
  logout() {}
}