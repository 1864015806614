import { styled } from "@mui/styles";

export const Footer = styled('div')({
  width: '100%',
  // padding: '20px',
  // borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  textAlign: "right"
});

export const stylePage = {
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column'
};

export const styleParkingPeriodLabel = {
  borderRadius: "16px",
  background: '#F0EFF4',
  width: '100%',
  padding: '1vh',
  marginLeft: 0,
  "& > span.MuiFormControlLabel-label": {
    width: '100%',
    marginLeft: '20px'
  }
}

export const styleParkingPriceInput = {
  "& .MuiOutlinedInput-root": {
    background: '#F0EFF4',
    padding: '1vh',
    borderRadius: '16px',
    '& fieldset': {
      display: 'none',
    },
  },
  "& .MuiOutlinedInput-input": {
    marginLeft: 'auto',
    width: '25%',
    padding: '9px 14px',
  },
  "& .MuiOutlinedInput-input, .MuiInputAdornment-root .MuiTypography-root": {
    color: '#5D31B8',
  },
  '& label.MuiInputLabel-root': {
    top: '30%',
    left: '10%',
    transform: 'none',
    color: '#000'
  },
}


export const stylePlaceAndPricesBlock = {
  marginBottom: "20px"
}
export const stylePlaceAndPricesTypography = {
  marginBottom: '10px',
  marginLeft: '10px',
}

export const stylePlaceAndPricesIcon = {
  position: 'relative',
  top: '3px',
}
