export const styleParkingPeriodLabel = {
  borderRadius: "0.83vw",
  background: '#F0EFF4',
  width: '100%',
  padding: '0.5vw 0 0.5vw 0', //1vh when 5 tabs
  marginLeft: 0,
  height: '2.11vw',
  "& > span.MuiFormControlLabel-label": {
    width: '100%',
    marginLeft: '10%',
    fontSize: '0.83vw'
  },
  "& > span.MuiButtonBase-root": {
    paddingRight: '5%'
  }
}

export const styleParkingPriceInput = {
  "& .MuiOutlinedInput-root": {
    background: '#F0EFF4',
    padding: '0.5vw',
    borderRadius: '0.83vw',
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
      borderColor: 'red'
    }
  },
  "& .MuiOutlinedInput-input": {
    marginLeft: 'auto',
    width: '25%',
    padding: '0.46vw 0.72vw',
  },
  "& .MuiOutlinedInput-input, .MuiInputAdornment-root .MuiTypography-root": {
    color: '#5D31B8',
    fontSize: '0.83vw'
  },
  '& label.MuiInputLabel-root': {
    top: '30%',
    left: '10%',
    transform: 'none',
    color: '#000',
    fontSize: '0.83vw'
  },
}

export const stylePlaceAndPricesTypography = {
  marginBottom: '10px',
  marginLeft: '10px',
  fontSize: '0.83vw'
}

export const stylePlaceAndPricesIcon = {
  position: 'relative',
  top: '0.20vw',
}
