import React, {useState, useEffect}  from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker from '@mui/lab/DateRangePicker';
import Box from '@mui/material/Box';

const CalendarsDateRangePicker = ({onChange, title}) => {
  const [value, setValue] = useState([null, null]);

  useEffect(() => {
    onChange && onChange(value)
  }, [onChange, value]);

  const styleInput ={
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
      padding: '0.25vw',
      borderRadius: '0.83vw',
      border: '1px solid',  
      borderColor: 'rgba(93, 49, 184, 1)',
      '& fieldset': {
        display: 'none',
      },
      '& input': {
        fontSize: "0.83vw"
    },
    },
    "& legend": {
      display: 'none',
    },
    '& label.MuiInputLabel-root': {
      top: '0.25vw',
      left: '0.5vw',
      fontSize: "0.83vw"
    },
    '& label.Mui-focused': {
      display: 'none'
    },
    '& label.MuiFormLabel-filled': {
      display: 'none'
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <Typography sx={{ mb: 2, fontSize: '0.83vw' }}>{title}</Typography>
        <DateRangePicker
          calendars={2}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(startProps, endProps) => (
            <>
              <TextField sx={styleInput} {...startProps} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField sx={styleInput} {...endProps} />
            </>
          )}
        />
      </Box>
    </LocalizationProvider>
  );
}
export default CalendarsDateRangePicker;
