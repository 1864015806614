import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  TextField,
  Autocomplete,
} from '@mui/material';

const InputSelect = React.forwardRef((props,ref) => {
  const {onChange, name, value, label, options, required, className, helperText, error, ...rest} = props;
  // console.log("🚀 ~ file: InputSelect.js ~ line 18 ~ props", props)

  // const classes = useStyles();
  const [effectiveValue, setEffectiveValue] = useState(null);

  useEffect(() => {
    const find = value && options && options.length && options.find(opt => {
      const isObject = typeof opt === 'object';
      const itemValue = isObject ? (opt.id || opt.value || opt.teamId) : opt;
      return value === itemValue
    })
    setEffectiveValue(find || null);
  }, [value, options]);

  const handleValue = opt => {
    let value = null;
    if(opt) {
      const isObject = typeof opt === 'object';
      value = isObject ? (opt.id || opt.value || opt.teamId) : opt;
    }
    
    onChange(value)//({name, value})
  }

  return (
    <div ref={ref} aria-invalid={error}>
    <FormControl
      // className={clsx(classes.formControl, className)}
      variant="outlined" 
      {...rest}
    >

      <Autocomplete
        {...rest}
        // className={error ? classes.invalidInput : ''}
        getOptionSelected={(opt, value) => (typeof opt === 'object') ?
          ((opt.name || opt.fullName || opt.id || opt.value || opt.teamName) ===
            (value.name || value.fullName || value.id || value.value || value.teamName)) :
          (value === '') ? opt : (opt === value)}
        getOptionLabel={(opt) => typeof opt === 'object' ? (opt.name || opt.label || opt.address) : opt}
        onChange={(event, newValue) => handleValue( newValue)}
        options={options}
        renderInput={(params) => <TextField autoComplete={"off"} {...params} required={required} label={label} variant="outlined" />}
        value={effectiveValue}
      />
      {helperText && (
        <FormHelperText error>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
    </div>);
})

InputSelect.propTypes = {
  className: PropTypes.string ,
  error: PropTypes.bool ,
  handleChange: PropTypes.func,
  helperText: PropTypes.string ,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool ,
  value: PropTypes.oneOfType([PropTypes.string,PropTypes.number])
}

InputSelect.defaultProps = {
  options: [],
  value: ''
}

export default InputSelect;
