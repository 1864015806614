import React from 'react';
import Page from "./../../components/Page"
import { Grid, Typography, Box, FormControl } from '@mui/material';
import { Link } from "react-router-dom";
import { ContainerForm, BoxInput } from "./styles";
import {ParkifyLogo, RegisterIllustration } from '../../layouts/SVGs';

const Start = (props) => {

  const styleTypography = {
    backgroundColor: '#80CC5E', 
    borderRadius: "0.83vw",
    color: '#fff',
    padding: '1.5vw',
    textAlign: 'center'
  };

  const styleLink = {textDecoration: 'none',};

  const styleMain = {minHeight: "100vh"}

  return <Page title="Регистрация">
  <Grid
    container
    direction="row"
    justifyContent="space-between"
    alignItems="stretch"
    sx={styleMain}
  >
    <Grid item xs={2}></Grid>
    <Grid item xs={5} alignSelf="flex-end" sx={{display: 'grid', gap: '5vh'}}>
      <Box 
        component="div"
        sx={{ 
          display: "flex",
          flexDirection: "column",
          gap: "0.77vw"
        }}
      >
        <ParkifyLogo />
        <Typography variant="h5" sx={{ width: 'max-content', fontSize: '1.24vw' }}>Cдавайте парковочное <br /> место в аренду тысячам PARKIFY <br /> пользователям</Typography>
      </Box>
      <RegisterIllustration />
    </Grid>
    <Grid item xs={5} > 
      <ContainerForm>
        <Box sx={{ 
          width: "23vw",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          alignItems: "center"
        }}>
            <Typography fontSize="1.24vw" textAlign={"center"}> Уже зарегестрированы? </Typography>
            <br />
            <FormControl fullWidth required>
              <BoxInput>
                <Link to={"/login"} style={styleLink}>
                  <Box>
                    <Typography sx={styleTypography}>Войти</Typography>
                  </Box>
                </Link>
              </BoxInput>
              <BoxInput>
                <Link to={"/register"} style={styleLink}>
                  <Box>
                    <Typography sx={styleTypography}>Зарегестрироваться</Typography>
                  </Box>
                </Link>
              </BoxInput>
            </FormControl>
        </Box>
      </ContainerForm>
    </Grid>
  </Grid>
</Page>
}

export default Start;