import React from 'react';

const sizes = {
  width: '1.03vw',
  height: '1.03vw'
}

const EditIcon = () => {	return (
<svg style={sizes} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.98136 13.7616L13.012 3.73096L16.3555 7.07449L6.3249 17.1051L2.14548 17.941L2.98136 13.7616Z" fill="#5D31B8"/>
<path d="M13.8481 2.89486L16.3557 0.387207L19.6992 3.73074L17.1916 6.23839L15.5198 4.56662L13.8481 2.89486Z" fill="#5D31B8"/>
</svg>
)
}

export default EditIcon;

