import {COLOR_PURPLE} from '../../../../constants/appConst';

export const styleFormControlLabel = {
  width: '25%',
  display: 'flex',
  background: COLOR_PURPLE,
  borderRadius: '0.83vw',
  color: "#fff",
  ':first-of-type': {
    marginLeft: '0px',
  },
  ':last-child': {
    marginRight: '0px',
  },
  margin: '0 10px',
  height: "20vh",
  alignItems: "baseline",
  textAlign: "center",
  position: "relative",
  
  "& > span.MuiFormControlLabel-label": {
    flex: '1 1 100%'
  },
  "& > span.MuiRadio-root svg": {
    fill: "#fff"
  },
  "& > span.MuiTypography-root p": {
    fontSize: "0.8vw",
  }
}

export const styleInput = {
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#fff",
    padding: '10px',
    borderRadius: '16px',
    border: '1px solid',  
    borderColor: 'rgba(0, 0, 0, 0.23)',
    '& fieldset': {
      display: 'none',
    },
  },
  "& legend": {
    display: 'none',
  },
  '& label.MuiInputLabel-root': {
    top: '10px',
    left: '5px',
  },
  '& label.Mui-focused': {
    display: 'none'
  },
  '& label.MuiFormLabel-filled': {
    display: 'none'
  },
  '& span.MuiFormControlLabel-label': {
    fontSize: "12px"
  }
}