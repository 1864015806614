import React from 'react';

const CheckboxIcon = () => {	return (
<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_221_4359)">
<circle cx="12" cy="11" r="11" fill="#6F3ADD"/>
</g>
<path d="M7.85355 10.6464C7.65829 10.4512 7.34171 10.4512 7.14645 10.6464C6.95118 10.8417 6.95118 11.1583 7.14645 11.3536L7.85355 10.6464ZM10.5 14L10.1464 14.3536C10.3417 14.5488 10.6583 14.5488 10.8536 14.3536L10.5 14ZM16.3536 8.85355C16.5488 8.65829 16.5488 8.34171 16.3536 8.14645C16.1583 7.95118 15.8417 7.95118 15.6464 8.14645L16.3536 8.85355ZM7.14645 11.3536L10.1464 14.3536L10.8536 13.6464L7.85355 10.6464L7.14645 11.3536ZM10.8536 14.3536L16.3536 8.85355L15.6464 8.14645L10.1464 13.6464L10.8536 14.3536Z" fill="#F0EFF4"/>
<defs>
<filter id="filter0_d_221_4359" x="0" y="0" width="24" height="25" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="0.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_221_4359"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_221_4359" result="shape"/>
</filter>
</defs>
</svg>
)
}

export default CheckboxIcon;

