import React from 'react';

const sizes = {
  width: "0.83vw", 
  height: "0.77vw"
}

const NumberParkingSpacesIcon = () => {	return (
<svg style={sizes} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M2.50038 6.87788L1.89296 7.17619C1.74871 7.24703 1.65517 7.39269 1.65517 7.55708V10.3147H14.3448V7.55708C14.3448 7.39541 14.2546 7.25201 14.114 7.17968L13.5282 6.8783L12.2001 3.1005C12.0003 2.53209 11.4634 2.15175 10.8609 2.15175H5.16758C4.56507 2.15175 4.0282 2.53209 3.82837 3.1005L2.50038 6.87788ZM14.8713 5.7079L13.7616 2.55154C13.3288 1.32039 12.1659 0.496582 10.8609 0.496582H5.16758C3.86256 0.496582 2.69971 1.32039 2.26688 2.55154L1.16334 5.69051C0.47429 6.0289 0 6.7376 0 7.55708V13.4503C0 14.0321 0.471614 14.5037 1.05338 14.5037H1.93594C2.51771 14.5037 2.98932 14.0321 2.98932 13.4503V11.9699H13.0107V13.4503C13.0107 14.0321 13.4823 14.5037 14.0641 14.5037H14.9466C15.5284 14.5037 16 14.0321 16 13.4503V7.55708C16 6.75139 15.5415 6.05278 14.8713 5.7079Z" fill="#6337BE"/>
<path fillRule="evenodd" clipRule="evenodd" d="M5.04586 8.93278C5.54729 8.93278 5.95377 8.52629 5.95377 8.02486C5.95377 7.52343 5.54729 7.11694 5.04586 7.11694C4.54443 7.11694 4.13794 7.52343 4.13794 8.02486C4.13794 8.52629 4.54443 8.93278 5.04586 8.93278ZM10.8085 8.93278C11.3099 8.93278 11.7164 8.52629 11.7164 8.02486C11.7164 7.52343 11.3099 7.11694 10.8085 7.11694C10.3071 7.11694 9.90058 7.52343 9.90058 8.02486C9.90058 8.52629 10.3071 8.93278 10.8085 8.93278Z" fill="#6337BE"/>
</svg>
)
}

export default NumberParkingSpacesIcon;