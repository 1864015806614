import { ButtonGroup, Grid, Typography, Box } from '@mui/material';
import React, { useState } from 'react';
import Page from '../../components/Page';
import ChartAnalytics from './Chart';
import {
  ExportButton,
  PeriodButton,
} from './styled';

import Sidebar from '../../components/Sidebar'
import Helmet from '../../components/Helmet'

const Analytics = props => {
  const [checkedPeriod, setCheckedPeriod] = useState('day');
  const periods = [
    {name: "День", key: "day"},
    {name: "Неделя", key: "week"},
    {name: "Месяц", key: "month"},
  ]
  const onExport = () => {
    console.log('export')
  }
  const onClickPeriod = (item) => {
    setCheckedPeriod(item.key)
  }
  const styleMain = {minHeight: "100vh", background: '#F0EFF4'}
  const stylePeriodNumber = {
    fontSize: '35px'
  }

  const stylePeriod = {
    color: '#5D31B8',
  }

  const stylePeriodBox = {
    marginLeft: '1vw'
  }

  const stylePeriodBlock = {
    color: '#000',
    background: '#fff',
    borderRadius: '16px',
    width: '100%',
    ':first-of-type': {
      marginLeft: '0px',
    },
    ':last-child': {
      marginRight: '0px',
    },
    margin: '0 10px',
    alignItems: "baseline",
    position: "relative",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }

  return (<Page title="Аналитика">
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      sx={styleMain}
    >
      <Grid item xs={1.7}>
        <Sidebar />
      </Grid>
      
      <Grid item xs={1}></Grid>

      <Grid item xs={8}>
        <Helmet />

        <Box>
          <Typography sx={{
            fontSize: '30px',
            color: '#5D31B8',
          }}>Аналитика</Typography>
        </Box>

        <Box sx={{
          marginTop: '3vh',
          display: 'flex',
          flexDirection: 'column',
          height: '25vh'
        }}>
          <Typography sx={{
            lineHeight: 1.5,
            letterSpacing: '0.00938em',
            fontSize: '15px',
            marginBottom: '15px',
          }}>Количество арендованных парковочных мест</Typography>

          <Box sx={{display: 'flex', flexGrow:'3'}}>
            <Box sx={stylePeriodBlock}>
              <Box sx={stylePeriodBox}>
                <Typography sx={stylePeriodNumber}>20</Typography>
                <Typography sx={stylePeriod}>Сейчас</Typography>
              </Box>
            </Box>

            <Box sx={stylePeriodBlock}>
              <Box sx={stylePeriodBox}>
                <Typography sx={stylePeriodNumber}>40</Typography>
                <Typography sx={stylePeriod}>За сегодня</Typography>
              </Box>
            </Box>

            <Box sx={stylePeriodBlock}>
              <Box sx={stylePeriodBox}>
                <Typography sx={stylePeriodNumber}>500</Typography>
                <Typography sx={stylePeriod}>За неделю</Typography>
              </Box>
            </Box>

            <Box sx={stylePeriodBlock}>
              <Box sx={stylePeriodBox}>
                <Typography sx={stylePeriodNumber}>2000</Typography>
                <Typography sx={stylePeriod}>За месяц</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{marginTop:'5vh'}}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>Test</Grid>

            <Grid item>
              <Grid item container>
                <Box>
                  <ButtonGroup
                    variant="outlined"
                    aria-label="outlined button group"
                  >
                    {periods.map(per => <PeriodButton
                      key={`period_${per.key}`}
                      onClick={() => onClickPeriod(per)}
                      active={checkedPeriod === per.key}
                    >
                      {per.name}
                    </PeriodButton>)}
                  </ButtonGroup>
                </Box>

                <Box>
                  <ExportButton onClick={onExport}>Export</ExportButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Box>
            <ChartAnalytics />
          </Box>
        </Box>

      </Grid>

      <Grid item xs={1.3}></Grid>

    </Grid>
  </Page>)
}

export default Analytics;