import React from 'react';
import Page from "./../../components/Page"
import AuthService from '../../services/auth.service';
import { Grid, Typography, TextField, Button, Box, FormControl, Checkbox, FormControlLabel } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { ContainerForm, BoxInput } from "./styles";
import { EMAIL_PATTERN } from './../../constants/appConst';
import { CheckboxIcon, CheckboxCheckedIcon, ParkifyLogo, RegisterIllustration } from '../../layouts/SVGs';
import { useDispatch } from 'react-redux';
import { setToken } from '../../utils/redux/registerReducer/actions'

const Register = (props) => {
  let navigate = useNavigate();
  const authService = new AuthService();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: 'elvis@presley.net',
      password:  'password',
    }
  });

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const token = await authService.signUp(data);
    
    if(token) {
      dispatch(setToken(token));
      const route = "/deals";
      navigate(route);
    }
  };
  const styleInput = {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
      padding: '0.5vw',
      borderRadius: '0.83vw',
      '&.Mui-focused fieldset': {
        border: 0,
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      border: 0,
    },
    '& label.MuiInputLabel-root': {
      top: '0.5vw',
      left: '0.25vw',
    },
    '& label.Mui-focused': {
      display: 'none'
    },
    '& label.MuiFormLabel-filled': {
      display: 'none'
    },
    '& span.MuiFormControlLabel-label': {
      fontSize: "0.65vw"
    },
    '& fieldset': {
      display: "none"
    },
  }
  const styleButton = {
    backgroundColor: '#80CC5E',
    width: "100%",
    paddingTop: "1.34vw", 
    paddingBottom: "1.34vw", 
    borderRadius: "0.83vw",
    textTransform: 'none'
  };

  const styleMain = {minHeight: "100vh"}

  return <Page title="Регистрация">
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
      sx={styleMain}
    >
      <Grid item xs={2}></Grid>
      <Grid item xs={5} alignSelf="flex-end" sx={{display: 'grid', gap: '5vh'}}>
        <Box 
          component="div"
          sx={{ 
            display: "flex",
            flexDirection: "column",
            gap: "15px"
          }}
        >
          <ParkifyLogo />
          <Typography variant="h5" sx={{ width: 'max-content', fontSize: '1.24vw' }}>Cдавайте парковочное <br /> место в аренду тысячам PARKIFY <br /> пользователям</Typography>
        </Box>
        <RegisterIllustration />
      </Grid>
      <Grid item xs={5} > 
        <ContainerForm>
          <Box sx={{ 
            width: "20vw",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            alignItems: "center"
          }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography fontSize="1.24vw" textAlign={"center"}> Зарегистрировать новое юридическое лицо </Typography>
              <br />
              <FormControl fullWidth required>
                <BoxInput>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: EMAIL_PATTERN,
                      }
                    }}
                    render={({ field, fieldState: { error } }) => <TextField
                      label="Email"
                      sx={styleInput}
                      variant="outlined"
                      fullWidth
                      error={error}
                      helperText={error?.message }
                      {...field}
                    />}
                  />
                </BoxInput>
                <BoxInput>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: true, minLength: 6 }}
                    render={({ field, fieldState: { error } }) => <TextField
                      label="Password"
                      type="password"
                      sx={styleInput}
                      variant="outlined"
                      fullWidth
                      error={error}
                      helperText={error?.message }
                      {...field}
                    />}
                  />
                </BoxInput>
                <BoxInput>
                  <Button
                    variant="contained"
                    type="submit"
                    sx={styleButton}
                  >Далее</Button>
                </BoxInput>
                <BoxInput>
                  <FormControlLabel
                    sx={styleInput}
                    control={
                      <Checkbox
                        required
                        icon={<CheckboxIcon />}
                        checkedIcon={<CheckboxCheckedIcon />}
                      />
                    }
                    label="Нажимая на кнопку “Далее” я соглашаюсь с  условиями Пользовательского соглашения"
                  />
                </BoxInput>
              </FormControl>
            </form>
          </Box>
          <Box sx={{
            position: 'absolute',
            bottom: '5vh',
            border: '1px solid white',
            borderRadius: '8px',
          }}>
            <Link to={"/login"} style={{textDecoration: 'none'}}>
              <Box>
                <Typography 
                  sx={{
                    color: '#fff',
                    padding: '1vw'
                  }}
                >Авторизоваться</Typography>
              </Box>
            </Link>
          </Box>
        </ContainerForm>
      </Grid>
    </Grid>
  </Page>
}

export default Register;