import React from 'react';

const sizes = {
  width: '1.81vw',
  height: '1.03vw'
}
const DealsIconSB = () => {	return (
<svg style={sizes} viewBox="0 -3 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.5426 3.58301H15.4395" stroke="white"/>
<path d="M15.4933 4.64121V2.38112C15.4933 1.39048 14.6902 0.587402 13.6996 0.587402H2.79372C1.80308 0.587402 1 1.39048 1 2.38112V10.0044C1 10.9951 1.80308 11.7982 2.79372 11.7982H8.2287" stroke="white"/>
<path d="M11.6726 9.43039L12.7847 10.7577L14.704 8.44385" stroke="white"/>
<circle cx="13.1972" cy="9.60982" r="3.30269" stroke="white"/>
</svg>
)
}

export default DealsIconSB;

