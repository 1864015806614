import React from 'react';

const sizes = {
  width: '0.98vw',
  height: '1.03vw'
}

const AddIcon = () => {	return (
<svg style={sizes} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.473633 9.94385H18.9736" stroke="white" strokeWidth="2"/>
<path d="M9.72363 0.75L9.72363 19.25" stroke="white" strokeWidth="2"/>
</svg>
)
}

export default AddIcon;

