import React from 'react';

const sizes = {
  width: '1.03vw',
  height: '1.55vw'
}

const PreviousButtonIcon = () => {	return (
<svg style={sizes} viewBox="5 0 20 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.8001 39.9595L1.81738 20.7454L21.8001 1.53125" stroke="white" strokeWidth="1.71412" strokeLinecap="round"/>
</svg>
)
}

export default PreviousButtonIcon