import React from 'react';

const sizes = {
  width: '1.08vw',
  height: '1.08vw'
}

const RadioCheckedIcon = () => {	return (
<svg style={sizes} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0 10.5C0 4.66667 4.66667 0 10.5 0C16.3333 0 21 4.66667 21 10.5C21 16.3333 16.3333 21 10.5 21C4.66667 21 0 16.3333 0 10.5ZM11.3333 16.6667H9V9.66667H11.3333V16.6667ZM9 6.16667C9 5.58333 9.58333 5 10.1667 5C10.75 5 11.3333 5.58333 11.3333 6.16667C11.3333 6.75 10.75 7.33333 10.1667 7.33333C9.58333 7.33333 9 6.75 9 6.16667Z" fill="#6337BE"/>
</svg>
)
}

export default RadioCheckedIcon;

