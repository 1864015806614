import { styled } from '@mui/system';
import {COLOR_PURPLE} from './../../constants/appConst'

export const ContainerForm = styled('div')({
  background: COLOR_PURPLE,
  color: "#fff",
  // padding: '20px',
  minHeight: '100%',
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
});

export const BoxInput = styled('div')({
  marginTop: "20px"
})

// const useStyles = makeStyles(theme => ({
//   submit: {
    
//   },
//   containerForm: `
//     backgroundColor: "#1ba4ea";
//     color: "#fff";
//   `
// }));

// export default useStyles;