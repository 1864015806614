import React from 'react';

const sizes = {
  width: '0.93vw',
  height: '0.93vw'
}

const ZoomIcon = () => {	return (
<svg style={sizes} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 17L13.2223 13.2156M8.15789 5.21053V8.15789M8.15789 8.15789V11.1053M8.15789 8.15789H11.1053M8.15789 8.15789H5.21053M15.3158 8.15789C15.3158 10.0563 14.5617 11.8769 13.2193 13.2193C11.8769 14.5617 10.0563 15.3158 8.15789 15.3158C6.2595 15.3158 4.43886 14.5617 3.0965 13.2193C1.75413 11.8769 1 10.0563 1 8.15789C1 6.2595 1.75413 4.43886 3.0965 3.0965C4.43886 1.75413 6.2595 1 8.15789 1C10.0563 1 11.8769 1.75413 13.2193 3.0965C14.5617 4.43886 15.3158 6.2595 15.3158 8.15789V8.15789Z" stroke="#5D31B8" strokeWidth="1.5" strokeLinecap="round"/>
</svg>
)
}

export default ZoomIcon;