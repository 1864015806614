export const DATE_FORMAT = 'DD.MM.YYYY';
export const COLOR_BLUE = "#1ba4ea";
export const COLOR_PURPLE = "linear-gradient(168.2deg, #6135BC 0%, #4F23AC 100.59%);";
export const COLOR_PURPLE_HEX = "#5D31B8";
export const HEIGHT_TOPBAR = 50;

export const EMAIL_PATTERN = /^[a-z0-9\u007F-\uffff!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9\u007F-\uffff!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z]{2,}$/i

export const getListNumberParkingSpace = (min, max) => {
  let list = [];
  for(let i = min; i <= max; i++) list.push({value: ''+i, label: ''+i})

  return list
}