export const defaultRequired = {
  parkingType: {
    required: true,
    message: "Выберите местонахождение парковочных мест"
  },
  offer: {
    required: true,
    message: 'Необходимо принять условия "Оферта".'
  },
  politics: {
    required: true,
    message: 'Необходимо принять условия "Политика".'
  },
  regulations: {
    required: true,
    message: 'Необходимо принять условия "Правила".'
  },
  accessSystemType: {
    required: true,
    message: 'Выберите удобную для вашей парковки пропускную систему'
  },
  acceptTheSecurityTerms: {
    required: true,
    message: 'Необходимо принять условия "Безопасная сделка".'
  }
}

export const defaultRequiredParkingPeriod = {
  hour: 'Введите стоимость парковки за час',
  day: 'Введите стоимость парковки за день',
  week: 'Введите стоимость парковки за неделю',
  month: 'Введите стоимость парковки за месяц',
}