export const defaultRequired = {
  coords: {
    type: Array,
    required: true,
    message: 'Укажите местоположение вашей парковки на карте.'
  },
  parkingType: {
    required: true,
    message: "Выберите местонахождение парковочных мест"
  },
  offer: {
    required: true,
    message: 'Необходимо принять условия "Оферта".'
  },
  politics: {
    required: true,
    message: 'Необходимо принять условия "Политика".'
  },
  regulations: {
    required: true,
    message: 'Необходимо принять условия "Правила".'
  },
  accessSystemType: {
    required: true,
    message: 'Выберите удобную для вашей парковки пропускную систему'
  },
  acceptTheSecurityTerms: {
    required: true,
    message: 'Необходимо принять условия "Безопасная сделка".'
  },
  parkingAddress: {
    required: false,
  },
  parkingPhone: {
    required: true,
    message: 'Необходимо заполнить поле.'
  },
  parkingPeriod: {
    day: {
      checked: {
        type: Boolean,
        required: true
      },
      price: {
        type: Number,
        required: false
      },
    },
    hour: {
      checked: {
        type: Boolean,
        required: true
      },
      price: {
        type: Number,
        required: false
      },
    },
    month: {
      checked: {
        type: Boolean,
        required: true
      },
      price: {
        type: Number,
        required: false
      },
    },
    week: {
      checked: {
        type: Boolean,
        required: true
      },
      price: {
        type: Number,
        required: false
      },
    },
  },
  numberParkingSpaces: {
    required: true,
    message: 'Необходимо заполнить поле.'
  },
  parkingPhoto: [{
    file: {
      type: File,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    order: {
      type: Number,
      required: true,}
  }],
}

export const defaultRequiredParkingPeriod = {
  hour: 'Введите стоимость парковки за час',
  day: 'Введите стоимость парковки за день',
  week: 'Введите стоимость парковки за неделю',
  month: 'Введите стоимость парковки за месяц',
}