import ApiManager from '../utils/apiManager'

export default class ParkingService {
  constructor() {
    this.apiManager = new ApiManager();
  }

  async createParking(accessToken, data) {
    try {
      const response = await this.apiManager.post({
        url: '/api/user/profile/parking',
        data,
        config: {
          headers: {
            Authorization: `token ${accessToken}`
          },
        },
      });
    
      if(data?.parkingPhoto) {
        await Promise.all(data.parkingPhoto.map(async (photo) => {
          let formData = new FormData();
          formData.append('image', photo.file, photo.file.name);
          formData.append('parking_id', response.data.id);
          formData.append('order', photo.order);
          await this.apiManager.post({
            url: `/api/user/profile/parking/image`,
            data: formData,
            config: {
              headers: {
                Authorization: `token ${accessToken}`,
                'Content-Type': 'multipart/form-data'
              },
            },
          });
        }))
      }
      return response.data;
    } catch (error) {
      console.log(error)
    }
  }

  async updateParking(accessToken, parkingId, data) {
    try {
      const response = await this.apiManager.patch({
        url: `/api/user/profile/parking/${parkingId}`,
        data,
        config: {
          headers: {
            Authorization: `token ${accessToken}`
          },
        },
      });
    
      if(data?.parkingPhoto) {
        await Promise.all(data.parkingPhoto.map(async (photo) => {
          let formData = new FormData();
          formData.append('image', photo.file, photo.file.name);
          formData.append('parking_id', parkingId);
          formData.append('order', photo.order);
          await this.apiManager.post({
            url: `/api/user/profile/parking/image`,
            data: formData,
            config: {
              headers: {
                Authorization: `token ${accessToken}`,
                'Content-Type': 'multipart/form-data'
              },
            },
          });
        }))
      }
      return response.data;
    } catch (error) {
      console.log(error)
    }
  }

  async deleteParking(accessToken, parkingId) {
    try {
      const response = await this.apiManager.delete({
        url: `/api/user/profile/parking/${parkingId}`,
        config: {
          headers: {
            Authorization: `token ${accessToken}`
          },
        },
      });
    
      return response.data;
    } catch (error) {
      console.log(error)
    }
  }

  async deleteParkingImage(accessToken, imgUrl) {
    try {
      const response = await this.apiManager.delete({
        url: `/api/user/profile/parking/image`,
        config: {
          params: {
            file_name: imgUrl?.replace(`${process.env.REACT_APP_API_BASE_URL}/media/`, '')
          },
          headers: {
            Authorization: `token ${accessToken}`
          },
        },
      });
    
      return response.data;
    } catch (error) {
      console.log(error)
    }
  }

  async getAllParkings(accessToken) {
    try {
      const response = await this.apiManager.get({
        url: '/api/user/profile/parking',
        config: {
          headers: {
            Authorization: `token ${accessToken}`
          },
        },
      });
    
      return response.data;
    } catch (error) {
      console.log(error)
    }
  }
}