import FirebaseAuth from './firebase/authFirebase';
import ApiManager from '../utils/apiManager'

export default class AuthService {
  constructor() {
    this.firebaseAuth = new FirebaseAuth();
    this.apiManager = new ApiManager();
  }

  async signUp(data) {
    const newUser = await this.firebaseAuth.createUser(data.email, data.password);
    try {
      const response = await this.apiManager.post({
        url: '/api/authorization',
        data: {
          firebase_token: newUser.accessToken
        }
      });
    
      return response.data.token;
    } catch (error) {
      this.firebaseAuth.deleteUser();
      console.log(error)
    }
  }

  async signIn(data) {
    const user = await this.firebaseAuth.signIn(data.email, data.password);
    console.log(user.accessToken)
    try {
      const response = await this.apiManager.post({
        url: '/api/authorization',
        data: {
          firebase_token: user.accessToken
        }
      });
    
      return response.data.token;
    } catch (error) {
      console.log(error)
    }
  }
}