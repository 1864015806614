import React from 'react';

const sizes = {
  width: "1.19vw", 
  height: "1.19vw"
}

const CheckboxCheckedIcon = () => {	return (
<svg style={sizes} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_223_5092)">
<circle cx="12.4736" cy="11.5" r="11" fill="#562AB2"/>
</g>
<path d="M8.32719 11.1464C8.13192 10.9512 7.81534 10.9512 7.62008 11.1464C7.42482 11.3417 7.42482 11.6583 7.62008 11.8536L8.32719 11.1464ZM10.9736 14.5L10.6201 14.8536C10.8153 15.0488 11.1319 15.0488 11.3272 14.8536L10.9736 14.5ZM16.8272 9.35355C17.0224 9.15829 17.0224 8.84171 16.8272 8.64645C16.6319 8.45118 16.3153 8.45118 16.1201 8.64645L16.8272 9.35355ZM7.62008 11.8536L10.6201 14.8536L11.3272 14.1464L8.32719 11.1464L7.62008 11.8536ZM11.3272 14.8536L16.8272 9.35355L16.1201 8.64645L10.6201 14.1464L11.3272 14.8536Z" fill="#F0EFF4"/>
<defs>
<filter id="filter0_d_223_5092" x="0.473633" y="0.5" width="24" height="25" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="0.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_223_5092"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_223_5092" result="shape"/>
</filter>
</defs>
</svg>
)
}

export default CheckboxCheckedIcon;