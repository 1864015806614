import React, {useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import InputSelect from "../../../../../../components/Base/InputSelect";
import ParkingService from '../../../../../../services/parking.service'; 
import useAuth from '../../../../../../hooks/useAuth';

const QRCode = () => {
  const {control} = useFormContext()
  const [parkings, setParkings] = useState([]);
  const {accessToken} = useAuth();

  useEffect(() => {
    const fetchParkingsData = async () => {
    const parkingService = new ParkingService();
    const parkingsData = await parkingService.getAllParkings(accessToken);
    return parkingsData;
    }

    fetchParkingsData().then(data => {
      setParkings(data);
    })
  }, [accessToken])

  const styleInput = {
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff",
      padding: '0.5vw',
      borderRadius: '0.83vw',
      '&.Mui-focused fieldset': {
        border: 0,
      },
    },
    "& .MuiOutlinedInput-root:hover": {
      border: 0,
    },
    '& label.MuiInputLabel-root': {
      top: '2%',
      left: '1%',
      fontSize: "0.8vw"
    },
    '& label.Mui-focused': {
      display: 'none'
    },
    '& label.MuiFormLabel-filled': {
      display: 'none'
    },
    '& input.MuiOutlinedInput-input': {
      fontSize: "0.8vw"
    },
    '& fieldset': {
      display: "none"
    },
    "& span.MuiInputLabel-asterisk": {
      display: 'none'
    },
    width: '15vw',
    marginTop: '1vh',
  }
  return (<>
    <Box>
    <Controller
        render={({ field }) => <><InputSelect
          label="Предложение парковки"
          required={true}
          sx={styleInput}
          options={parkings}
          {...field}
        /></>}
        name="accessSystemQRCode.address"
        control={control}
      />
    </Box>
    <Typography sx={{ fontSize: '0.8vw', marginTop: '2vh' }}>При оплате парковочного места, арендатору и арендателю вместе с цифровым чеком, отправляется QR code.</Typography>
  </>)
}

export default QRCode;