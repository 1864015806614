export const styleRadioLabelTitle = {
  fontWeight: 500,
  fontSize: '1.45vw',
  lineHeight: '1.97vw',
}

export const styleRadioLabelSubtitle = {
  fontSize: '0.8vw',
  lineHeight: '1.14vw',
  marginTop: '1vh',
}