import { styled } from '@mui/system';
import { Button } from '@mui/material';

export const PeriodButton = styled(Button)(props =>({
  color: props.active ? "#fff" : "#000",
  backgroundColor: props.active ? "rgba(128, 204, 94, 1)" : "",
  borderColor: 'rgba(117, 192, 74)',
  borderRadius: '8px',
  padding: '5px 25px',
  textTransform: 'none',
  fontWeight: 400,
  fontSixe: '10px',
  "&:hover": {
    backgroundColor: "rgba(128, 204, 94, 1)",
    borderColor: 'rgba(117, 192, 74, 1)',
    color: "#fff",
  }
}))

export const ExportButton = styled(Button)({
  background: '#75c04a',
  color: '#fff',
  marginLeft: 20,
  fontWeight: 400,
  borderRadius: '8px',
  padding: '5px 20px',
  border: '1px solid #75c04a',
  // boxShadow: 'none',
  // textTransform: 'none',
  // fontSize: 16,
  // padding: '6px 12px',
  // border: '1px solid',
  // lineHeight: 1.5,
  // backgroundColor: '#0063cc',
  // borderColor: '#0063cc',
  // fontFamily: [
  //   '-apple-system',
  //   'BlinkMacSystemFont',
  //   '"Segoe UI"',
  //   'Roboto',
  //   '"Helvetica Neue"',
  //   'Arial',
  //   'sans-serif',
  //   '"Apple Color Emoji"',
  //   '"Segoe UI Emoji"',
  //   '"Segoe UI Symbol"',
  // ].join(','),
  '&:hover': {
    backgroundColor: '#61d021',
  //   borderColor: '#0062cc',
  //   boxShadow: 'none',
  },
  '&:active': {
  //   boxShadow: 'none',
    // backgroundColor: '#61d021',
  //   borderColor: '#005cbf',
  },
  // '&:focus': {
  //   boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  // },
});
