import React from 'react';

const sizes = {
  width: '1.03vw',
  height: '1.55vw'
}

const NextButtonIcon = () => {	return (
<svg style={sizes} viewBox="-5 0 29 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.20088 1.53168L21.1836 20.7458L1.20089 39.96" stroke="white" strokeWidth="1.71412" strokeLinecap="round"/>
</svg>
)
}

export default NextButtonIcon