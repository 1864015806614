import { combineReducers, configureStore } from "@reduxjs/toolkit";
import registerReducer from './registerReducer/reducer';
import apiCacheReducer from './apiCacheReducer/reducer';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import { logout } from './registerReducer/actions'

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['registerReducer', 'apiCacheReducer']
};

const reducers = combineReducers({
  registerReducer,
  apiCacheReducer,
});

const rootReducer = (state, action) => {
  if(action.type === logout().type) {
    storage.removeItem('persist:root');
    return reducers(undefined, action);
  }
   return reducers(state, action);
}

const persistedRootReducer = persistReducer(persistConfig, rootReducer);

const initRedux = () => {
  const store = configureStore({
    reducer: persistedRootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER
        ],
      },
    }),
  });
  const persistor = persistStore(store);
  return { store, persistor }
}

export default initRedux;