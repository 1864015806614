import firebaseApp from './initFirebase' 
import { 
  getAuth, 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  deleteUser
} from "firebase/auth";

export default class FirebaseAuth {
  constructor() {
    this.auth = getAuth(firebaseApp);
    this.auth.languageCode = 'en';
  }

  async createUser(email, password) {
    try {
      const userCredential = await createUserWithEmailAndPassword(this.auth, email, password)
      const user  = userCredential.user;
      return user;
    } catch (error) {
      console.log(error)
    }
  }

  async signIn(email, password) {
    try {
      const userCredential = await signInWithEmailAndPassword(this.auth, email, password)
      const user  = userCredential.user;
      return user;
    } catch (error) {
      console.log(error)
    }
  }

  async deleteUser() {
    try {
      await deleteUser(this.auth.currentUser);
    } catch (error) {
      console.log(error)
    }
  }
}
