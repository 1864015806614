import React, {useState, useEffect, useCallback} from 'react';
import {
  Grid, Typography, TextField, 
  FormGroup, FormControlLabel,
  FormHelperText,
  Checkbox, Box, Button, IconButton, Fade,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import 'react-drop-zone/dist/styles.css';
import {getListNumberParkingSpace} from '../../constants/appConst';
import InputNumber from '../Base/InputNumber';
import InputSelect from "../Base/InputSelect";
import {
  styleParkingPeriodLabel,
  stylePlaceAndPricesTypography,
  stylePlaceAndPricesIcon,
  styleParkingPriceInput,
} from './styles';

import {defaultRequiredParkingPeriod} from './rules';
import { 
  CheckboxCheckedIcon, 
  CheckboxIcon, 
  PhotoIcon, 
  NumberParkingSpacesIcon, 
  ParkingOfferIcon, 
  ParkingPeriodIcon, 
  ParkingPriceIcon, 
  UploadPhotoIcon,
  DeleteButtonIcon,
  ZoomIcon
} from './SVGs';
import PhotoSlider from '../PhotoSlider/PhotoSlider';

const period = [{
  value: 'hour',
  title: 'Часовая',
  titlePrice: 'За час'
},{
  value: 'day',
  title: 'Суточная',
  titlePrice: 'За сутки'
},{
  value: 'week',
  title: 'Недельная',
  titlePrice: 'За неделю'
},{
  value: 'month',
  title: 'Месячная',
  titlePrice: 'За месяц'
}];

const minNumber = 1;
const maxNumber = 500;
const listNumberParkingSpaces = getListNumberParkingSpace(minNumber, maxNumber);

const allowedPhotoFormats = ['jpeg', 'png', 'gif'];

const defaultValueParking = {
  hour:  {checked: true, price: null},
  day:   {checked: true, price: null},
  week:  {checked: true, price: null},
  month: {checked: true, price: null},
}

const PlaceAndPrices = ({isAdminPannel, defaultValue}) => {
  const {control, errors, setValue, ...methods} = useFormContext();
  const [hovering, setHovering] = useState(null);
  const [showSlider, setShowSlider] = useState(false);
  const watchParkingPeriod = useWatch({
    control,
    name: "parkingPeriod",
    defaultValue: defaultValue?.parkingPeriod || defaultValueParking
  });
  // console.log("🚀 ~ file: PlaceAndPrices.js ~ line 66 ~ PlaceAndPrices ~ watchParkingPeriod", watchParkingPeriod)
  // console.log("🚀 ~ file: PlaceAndPrices.js ~ line 66 ~ PlaceAndPrices ~ defaultValue", defaultValue)

  const imageRef = React.useRef(null);
  const [files, setFiles] = useState([]);
  const [filesToUpload, setFilesToUpload] = useState([]);
  // console.log("🚀 ~ file: PlaceAndPrices.js ~ line 70 ~ PlaceAndPrices ~ files", files)

  const handleChangeImages = ({target}) => {
    const eventFiles = target.files;
    console.log("handleChangeImages -> eventFiles", eventFiles)
    let uploadFile = [...eventFiles].map((file, idx) => {
      return allowedPhotoFormats.includes(file.type.replace('image/', '')) ? {file, url: URL.createObjectURL(file), order: files.slice(-1)[0]?.order+idx+1 || 1} : null;
    })
    uploadFile = uploadFile.filter(Boolean) 
    let newFiles = [...files, ...uploadFile];
    if(newFiles.length > 5) {
      newFiles = newFiles.slice(0, 5-newFiles.length)
    }
    setFiles(newFiles);
    const newFilesToUpload = [...filesToUpload, ...uploadFile];
    console.log("🚀 ~ file: PlaceAndPrices.js ~ line 92 ~ PlaceAndPrices ~ newFilesToUpload", newFilesToUpload);
    setFilesToUpload(newFilesToUpload);
    setValue('parkingPhoto', newFilesToUpload);
  };
  
  const handleChangeDefault = useCallback(() => {
    setFiles([])
    setFilesToUpload([])
    const copyDefault = structuredClone(defaultValue?.images);
    const images = copyDefault?.map(file => {
      file.url = `${process.env.REACT_APP_API_BASE_URL}/media/${file.url}`;
      return file;
    });

    images?.sort((a, b) => a.order-b.order);
    console.log("🚀 ~ file: PlaceAndPrices.js ~ line 108 ~ PlaceAndPrices ~ images", images);

    setFiles(images || []);
  }, [defaultValue?.images])

  useEffect(() => {
    Object.keys(watchParkingPeriod).forEach(key => {
      if(!watchParkingPeriod[key].checked && watchParkingPeriod[key].price) {
        setValue(`parkingPeriod.${key}.price`, null,  {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true
        })
      }
    })
    
    if(defaultValue) handleChangeDefault();
  }, [defaultValue, handleChangeDefault, setValue, watchParkingPeriod]);
  
  const removeImage = (url) => {
    console.log("🚀 ~ file: PlaceAndPrices.js ~ line 88 ~ removeImage ~ url", url)
    const cloneFiles = cloneDeep(files);
    const [removedImg] = cloneFiles.splice(cloneFiles.findIndex(file => file.url === url), 1);


    if(defaultValue && removedImg.id) { // existed images has id
      setValue('parkingPhotoToDelete', [...methods.getValues().parkingPhotoToDelete, removedImg])
    } else {
      const cloneFilesToUpload = cloneDeep(filesToUpload);
      cloneFilesToUpload.splice(cloneFilesToUpload.findIndex(file => file.url === url), 1);
      setValue('parkingPhoto', cloneFilesToUpload);
    }
    setFiles(cloneFiles);
  }
  
  const displayPhotoSlider = (display) => {
    setShowSlider(display);
    document.body.style.overflow = display ? 'hidden' : 'visible';
  };

  const styleImg = {
    width: '4vw',
    height: '4vw',
    borderRadius: '16px',
    backgroundSize: `cover`
  }

  return <>
    <Grid container spacing={3} sx={{
      background: '#fff',
      borderRadius: '16px',
      width: '100%',
      padding: '5vh',
      margin: 0,
    }}>
      <Grid item xs={3}>
        <Box sx={{display:'flex'}}>
          <Box sx={stylePlaceAndPricesIcon}><ParkingOfferIcon /></Box>
          <Typography sx={stylePlaceAndPricesTypography}>Предложение парковки</Typography>
        </Box>

        <Box>
          <Controller
            name="parkingAddress"
            rules={{ required: true }}
            control={control}
            render={({ field }) => <>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#F0EFF4",
                  padding: '1.03vw',
                  borderRadius: '0.83vw',
                  fontSize: '0.83vw',
                  '& fieldset': {
                    display: 'none',
                  },
                },
              }}
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              disabled
              {...field} 
            /></>}
          />
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box sx={{display:'flex'}}>
          <Box sx={stylePlaceAndPricesIcon}><ParkingPeriodIcon /></Box>
          <Typography sx={stylePlaceAndPricesTypography}>Период парковки</Typography>
        </Box>

        <Box>
          <FormGroup>
            <Box>
              {period.map((option, i) => (<Box
                key={`parkingPeriod_${option.value}_checked`}
                sx={{marginBottom:'15px'}}
              ><Controller
                  name={`parkingPeriod[${option.value}].checked`}
                  control={control}
                  rules={{ required: period.some((period) => watchParkingPeriod[period.value].checked) ? false : true }}
                  render={({ field, fieldState: { error } }) => (<>
                    <FormControlLabel
                      {...field}
                      sx={styleParkingPeriodLabel}
                      labelPlacement="start"
                      label={option.title}
                      control={<Checkbox disableTouchRipple checked={watchParkingPeriod[option.value].checked} icon={<CheckboxIcon />} checkedIcon={<CheckboxCheckedIcon />}/>}
                    />
                    {error?.message && <FormHelperText error>{error.message}</FormHelperText>}
                  </>)}
                />
              </Box>))}
            </Box>
          </FormGroup>
          {errors?.parkingPeriod && <p>{errors.parkingPeriod.message}</p>}
        </Box>

      </Grid>

      <Grid item xs={3}>
        <Box sx={{display:'flex'}}>
          <Box sx={stylePlaceAndPricesIcon}><ParkingPriceIcon /></Box>
          <Typography sx={stylePlaceAndPricesTypography}>Стоимость парковки</Typography>
        </Box>

        <Box>
          <Box>
            {period.map((option) => <Box
              sx={{marginBottom:'15px'}}
              key={`parkingPeriod_${option.value}_price`}
            >
              <Controller
                name={`parkingPeriod[${option.value}].price`}
                control={control}
                rules={{ required: watchParkingPeriod[option.value].checked ? defaultRequiredParkingPeriod[option.value] : false}}
                render={({ field, fieldState: { error } }) => (
                  <InputNumber
                    {...field}
                    sx={{
                      ...styleParkingPriceInput,
                      '& fieldset': {
                        display: error ? 'block' : 'none',
                      }
                    }}
                    fullWidth
                    focused
                    autoComplete='off'
                    label={option.titlePrice}
                    InputLabelProps={{
                      shrink: false,
                      disableAnimation: true
                    }}
                    disabled={!watchParkingPeriod[option.value].checked}
                />)}
              />
            </Box>)}
          </Box>
        </Box>
      </Grid>

      <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          <Box sx={{display:'flex'}}>
            <Box sx={stylePlaceAndPricesIcon}><NumberParkingSpacesIcon /></Box>
            <Typography sx={stylePlaceAndPricesTypography}>Количество мест</Typography>
          </Box>

          <Box>
            <Controller
                render={({field}) => <InputSelect
                  options={listNumberParkingSpaces}
                  sx={{ 
                    width: "100%",
                    background: '#F0EFF4',
                    borderRadius: '0.83vw',
                    height: "3.2vw",
                    "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
                      height: '1vw',
                      padding: '0.6vw 0.5vw'
                    },
                    '& .MuiAutocomplete-endAdornment': {
                      paddingLeft: '2vw'
                      
                    },
                    '& .MuiOutlinedInput-root': {
                      fontSize: '0.83vw',
                      padding: '0.47vw'
                    },
                    "& label, & fieldset": {
                      display: 'none'
                    },
                    '& svg.MuiSvgIcon-root': {
                      width: "1.4vw",
                      height: "1.4vw"
                    }
                  }}
                  label="Количество мест"
                  {...field}
                /> }
                name="numberParkingSpaces"
                control={control}
              />
        </Box>

        </Box>

        <Box sx={{marginTop: '2vh', flex: '1 0 auto', display:'flex', flexDirection: 'column', justifyContent: 'space-between',}}>
          <Box sx={{display:'flex'}}>
            <Box sx={stylePlaceAndPricesIcon}><PhotoIcon /></Box>
            <Typography sx={stylePlaceAndPricesTypography}>Фото парковки</Typography>
          </Box>
              
          <Box sx={{marginTop: '1vh'}}>
            <input
              ref={imageRef}
              onChange={handleChangeImages}
              type="file"
              style={{ display: "none" }}
            />
            <Button 
              type='button'
              disableRipple
              onClick={() => imageRef.current.click()}
              variant='contained'
              sx={{
                display: 'inline-flex',
                backgroundColor: '#5D31B8',
                boxShadow: 'none',
                borderRadius: '0.83vw',
                padding: '1.03vw 1.03vw',
                width: '100%',
                textTransform: 'none',
                fontWeight: 'normal',
                '&:hover': {
                  backgroundColor: '#5D31B8',
                },
              }}
              disabled={files.length >= 5}
            ><UploadPhotoIcon /><Typography sx={{marginLeft: '1vw', fontSize: '0.83vw'}}>Добавить фото</Typography></Button>
          </Box>
              
          <Grid container sx={{
            display: 'flex',
            marginBottom: '15px',
            marginTop: '2vh',
            justifyContent: files.length > 1 ? 'space-between' : 'flex-start',
            gap: files.length > 1 ? 'inherit' : '1vw'
          }}>
            {files?.slice(0,  2)?.map((itemFile, index) => (
              <Grid key={index} item style={{
              ...styleImg,
              backgroundImage: `url(${itemFile.url})`,
              }}>
                <Box 
                  sx={{
                    "&:hover": {
                      background: 'rgba(255, 0, 0, 0.3)',
                    },
                    transition: 'background 0.2s',
                    width: '100%',
                    height: '100%',
                    borderRadius: '16px'
                  }}
                  onMouseEnter={() => setHovering(index)}
                  onMouseLeave={() => setHovering(null)}
                >
                  {hovering === index && (
                    <Fade in={hovering === index}><Box sx={{
                      width: 'fit-content',
                      position: 'relative',
                      top: '50%',
                      left: '50%',
                      marginRight: '-50%',
                      transform: 'translate(-50%, -50%)'
                    }}>
                      <DeleteButtonIcon />
                    </Box></Fade>
                  )}
                  <IconButton 
                    sx={{
                      width: '100%',
                      height: '100%',
                    }} 
                    disableRipple
                    onClick={()=>removeImage(itemFile.url)}>
                  </IconButton>
                </Box>
              </Grid>
            ))}
            {files && !!files.length && (
            <Grid item style={{
              ...styleImg,
              position: 'relative'
            }}>
              <Button
                type='button'
                disableRipple
                onClick={() => displayPhotoSlider(true)}
                variant='text'
                sx={{ 
                  padding: 0, 
                  backgroundColor: '#F0EFF4',
                  borderRadius: '25%',
                  minWidth: 0,
                  width: '100%',
                  height: '100%',
                  '&:hover': { 
                    backgroundColor: '#F0EFF4', 
                    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)',
                  }
                }}
              >
                <Typography sx={{
                  color: '#5D31B8',
                  fontSize: files?.length > 2 ? '0.93vw' : '0.5vw',
                  margin: 0,
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginRight: '-50%',
                  transform: 'translate(-50%, -60%)'
                }}>{files?.length > 2 ? `+${files?.length - 2}` : <ZoomIcon />}</Typography>
              </Button>
            </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    </Grid>
    
    {showSlider && (<PhotoSlider photos={files} onRemove={(url) => removeImage(url)} onClose={() => displayPhotoSlider(false)} />)}
  </>
}

export default PlaceAndPrices;