import React from 'react';

const sizes = {
  width: '0.98vw',
  height: '1.03vw'
}

const DeleteParkingIcon = () => {	return (
<svg style={sizes} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M8.0272 0.45153C7.64402 0.557047 7.47182 0.658159 7.20013 0.937092C6.96975 1.17359 6.74976 1.61501 6.74976 1.84077C6.74976 2.11009 6.89213 2.09601 4.14944 2.09839C2.75306 2.09961 1.51131 2.11976 1.38994 2.1432C1.14584 2.19034 0.961797 2.30887 0.803622 2.52071C0.724231 2.62709 0.699219 2.73635 0.699219 2.97721C0.699219 3.24612 0.720636 3.32197 0.84109 3.47989C1.12068 3.84651 0.361098 3.81765 9.73705 3.81765C19.113 3.81765 18.3534 3.84651 18.633 3.47989C18.7535 3.32197 18.7749 3.24612 18.7749 2.97721C18.7749 2.73635 18.7499 2.62709 18.6705 2.52071C18.5197 2.31879 18.3394 2.19722 18.1128 2.14462C18.0035 2.1193 16.7603 2.09804 15.35 2.09738C12.5795 2.09606 12.7243 2.11024 12.7243 1.84077C12.7243 1.78837 12.6764 1.61754 12.6179 1.46108C12.4749 1.07876 12.0757 0.679222 11.6778 0.520086C11.394 0.406619 11.323 0.401606 9.83832 0.390062C8.6157 0.380543 8.23844 0.393303 8.0272 0.45153ZM2.40653 11.096L2.42071 16.6023L2.57691 17.0386C2.88577 17.9013 3.52181 18.6325 4.29157 19.0096C5.10087 19.4061 4.82548 19.387 9.73705 19.387C14.6619 19.387 14.3701 19.4077 15.2047 18.9986C15.9879 18.6146 16.6453 17.8211 16.9593 16.8807C17.0468 16.6185 17.0531 16.2809 17.067 11.096L17.0817 5.58978H14.9284H12.775L12.7739 9.75427C12.7728 13.7122 12.768 13.9291 12.6781 14.1272C12.4089 14.7201 11.6064 14.8208 11.2172 14.3106L11.0788 14.1292L11.0653 9.85948L11.0518 5.58978H9.73705H8.42229L8.40882 9.85948L8.3953 14.1292L8.25692 14.3106C7.86771 14.8208 7.06525 14.7201 6.79598 14.1272C6.70606 13.9291 6.7013 13.7122 6.70024 9.75427L6.69912 5.58978H4.54574H2.39241L2.40653 11.096Z" fill="#5D31B8"/>
</svg>
)
}

export default DeleteParkingIcon;

