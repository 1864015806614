import React from 'react';

const sizes = {
  width: "0.88vw", 
  height: "0.77vw"
}

const UploadPhotoIcon = () => {	return (
<svg style={sizes} viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.58696 2.5V0.5H3.97826V2.5H6.06522V3.83333H3.97826V5.83333H2.58696V3.83333H0.5V2.5H2.58696ZM4.67391 6.5V4.5H6.76087V2.5H11.6304L12.9035 3.83333H15.1087C15.8739 3.83333 16.5 4.43333 16.5 5.16667V13.1667C16.5 13.9 15.8739 14.5 15.1087 14.5H3.97826C3.21304 14.5 2.58696 13.9 2.58696 13.1667V6.5H4.67391ZM9.54348 12.5C11.4635 12.5 13.0217 11.0067 13.0217 9.16667C13.0217 7.32667 11.4635 5.83333 9.54348 5.83333C7.62348 5.83333 6.06522 7.32667 6.06522 9.16667C6.06522 11.0067 7.62348 12.5 9.54348 12.5ZM7.31739 9.16667C7.31739 10.3467 8.31217 11.3 9.54348 11.3C10.7748 11.3 11.7696 10.3467 11.7696 9.16667C11.7696 7.98667 10.7748 7.03333 9.54348 7.03333C8.31217 7.03333 7.31739 7.98667 7.31739 9.16667Z" fill="white"/>
</svg>
)
}

export default UploadPhotoIcon;