import ApiManager from '../utils/apiManager'

export default class UtilsService {
  constructor() {
    this.apiManager = new ApiManager();
  }

  async getAddressByCoords(accessToken, params) {
    try {
      const response = await this.apiManager.get({
        url: '/api/user/profile/parking/address',
        config: {
          params: {
            geocode: `${params.latitude},${params.longitude}`,
            lang: params.lang,
            api_key: process.env.REACT_APP_YANDEX_API_KEY,
            format: 'json',
          },
          headers: {
            Authorization: `token ${accessToken}`
          },
        },
      });
    
      return response.data;
    } catch (error) {
      console.log(error)
    }
  }

}