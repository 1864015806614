import UtilsService from "../services/utils.service";

export const fetchAddress = async (accessToken, coords) => {
  const utilsService = new UtilsService();
  const response = await utilsService.getAddressByCoords(accessToken, {
    latitude: coords[0],
    longitude: coords[1],
    lang: 'ru_RU'
  })
  return response;
}