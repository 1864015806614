import React from 'react';

const sizes = {
  width: '1.6vw',
  height: '1.81vw',
}

const PoliticsIcon = () => {	return (
<svg style={sizes} viewBox="0 0 31 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M13.8762 0.938728C10.9951 3.38832 7.47929 4.57028 3.07463 4.57028C1.23614 4.57028 1.04401 4.59789 0.768589 4.90233C0.485283 5.2153 0.468405 5.53883 0.474557 10.5416C0.479762 14.698 0.540493 16.2726 0.755181 17.803C1.55289 23.4922 2.61876 25.8982 5.6225 28.7901C7.87491 30.9586 10.2597 32.6616 13.2082 34.2072C15.1718 35.2366 15.5521 35.2481 17.3034 34.3315C20.5477 32.6334 22.9824 30.8886 25.2538 28.6341C27.7776 26.1292 28.7492 24.3083 29.5041 20.6687C30.155 17.5308 30.2817 15.848 30.2787 10.3807C30.276 5.55444 30.257 5.20725 29.9782 4.89918C29.7062 4.59868 29.5075 4.57028 27.675 4.57028C23.2823 4.57028 19.6826 3.34857 16.7945 0.877366C16.1435 0.320376 15.6251 0 15.3748 0C15.1219 0 14.5843 0.336623 13.8762 0.938728ZM16.2938 2.88386C19.2523 5.1309 22.36 6.22816 26.29 6.41351L28.2925 6.50784L28.4025 7.38678C28.5772 8.78612 28.3518 16.2928 28.0797 18.1362C27.4547 22.3686 26.5324 24.5827 24.4997 26.7302C23.7888 27.4812 22.6338 28.5565 21.9331 29.1198C20.5398 30.2401 17.7166 32.0516 16.2733 32.7517L15.3578 33.1959L14.1447 32.5714C11.3479 31.1317 8.156 28.7595 6.15882 26.6365C4.20234 24.5568 3.2472 22.208 2.66198 18.0379C2.39681 16.1489 2.1725 8.37473 2.34917 7.19702L2.45928 6.4632L3.82975 6.46257C7.5416 6.46083 11.4979 5.16797 14.2157 3.06873C14.8098 2.60986 15.3191 2.22765 15.3473 2.21929C15.3756 2.21092 15.8015 2.51 16.2938 2.88386ZM17.1743 15.9672C15.7261 17.5505 14.3736 19.0219 14.169 19.2371L13.7969 19.6281L12.3351 18.1723C10.7846 16.6283 10.4234 16.4624 9.85379 17.032C9.26951 17.6163 9.44256 17.9324 11.4696 19.9827C13.177 21.7098 13.4554 21.9308 13.8712 21.8897C14.2608 21.8512 14.9167 21.2126 17.6974 18.1652C21.2376 14.2857 21.443 13.9631 20.765 13.3495C20.1585 12.8005 19.8959 12.992 17.1743 15.9672Z" fill="white"/>
</svg>
)
}

export default PoliticsIcon;